<template>
  <tabbar-page>
    <div class="block-box" style="text-align: center">
      <van-image
        src="https://img01.yzcdn.cn/vant/cat.jpeg"
        round
        fit="cover"
        width="7rem"
        height="7rem"
      />
      <div class="user-name">{{ userInfo.nickname }}</div>
    </div>
    <van-cell-group>
      <van-cell
        :title="$t('我的广告')"
        :value="$t('我发布过的广告')"
        is-link
        @click="goUrl('reserve/my-reserve')"
      />
      <van-cell
        :title="$t('我的账号')"
        :value="$t('查看或修改账号')"
        is-link
        @click="goUrl('my/user-edit')"
      />
    </van-cell-group>
    <van-cell-group style="margin-top: 0.5rem" @click="$toast('敬请期待')">
      <van-cell :title="$t('关于我们')" is-link />
    </van-cell-group>

    <div style="margin: 2rem 0.5rem">
      <van-button
        round
        block
        type="info"
        @click="onLogout"
        v-if="$$isLogined"
        >{{ $t("退出登录") }}</van-button
      >
    </div>
  </tabbar-page>
</template>

<script>
import { Image as VanImage, Cell, CellGroup, Button } from "vant";
import TabbarPage from "../../components/tabbar-page";

export default {
  components: {
    [TabbarPage.name]: TabbarPage,
    [VanImage.name]: VanImage,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
  },

  data() {
    return {
      userInfo: {},
    };
  },
  async mounted() {
    let res = await this.$userApi.customer.getSelfInfo(
      {},
      {
        skipErrorToast: true,
      }
    );
    if (res.status == 200) {
      this.userInfo = res.data;
    }
  },
  methods: {
    onLogout() {
      this.$store.commit("setToken", {
        accessToken: null,
        refreshToken: null,
      });
    },
    goUrl(url) {
      if (!this.checkLogin()) return;

      this.$router.push(url);
    },
    checkLogin() {
      if (!this.$$isLogined) {
        this.$router.push("/login");
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .block-box {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
}

/deep/ .user-name {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>